import React from 'react'
import { Box } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import countries from 'i18n-iso-countries'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import NamesLayout from '../layout/names'
import SEO from '../components/SEO/PageSEO'
import { components } from '../components/ContentWithStyles/TransformToChakra'

const CountryPageTemplate = ({ pageContext, data }) => {
  // console.log(data)
  const { countryCode, permalink, body } = pageContext
  const countryName = countries.getName(countryCode, 'es', {
    select: 'official',
  })

  const title = `Generar nombres y apellidos de ${countryName} aleatorios`
  const headerText = `Generador de nombres y apellidos de ${countryName}`
  const description = `Generador de nombres y apellidos de ${countryName} al azar, populares, originales y curiosos.`
  const cover = `nombres-apellidos-${countryCode}.png`
  return (
    <NamesLayout
      names={data.namesByCountryJson.names}
      headerText={headerText}
      // sex={sex}
    >
      <SEO
        title={title}
        titleSeo={title}
        description={description}
        image={cover}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[
          { name: 'Nombres', url: '/nombres/' },
          { name: 'País', url: '/nombres/pais/' },
        ]}
        permalink={permalink}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Box>
        <MDXProvider components={components}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
      </Box>
    </NamesLayout>
  )
}

export default CountryPageTemplate

export const pageQuery = graphql`
  query ($countryCode: String!) {
    namesByCountryJson(country: { eq: $countryCode }) {
      names {
        female
        male
        surnames
      }
      country
    }
  }
`
